<template>
  <v-navigation-drawer
    :value="isSidebarDataAPPActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-data-app-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          APP
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-data-app-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text class="pt-5">
        <v-form ref="stepForm">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="app.app_name"
                :label="`${$t('lbl.name')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>

            <!--<v-col
              v-if="app.slug !== 'hotetecapi' &&
                app.slug !== 'dingusapi' &&
                app.slug !== 'solwaysapi' &&
                app.slug !== 'paypal'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="app.key"
                :label="app.slug === 'sibs' ? 'Client-ID' : 'KEY'"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="app.slug === 'sibs'"
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="app.token"
                label="Access Token"
                outlined
                rows="3"
                hide-details
              ></v-textarea>
            </v-col>
            <v-col
              v-if="app.slug === 'sibs'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="app.terminal"
                :label="`${$t('lbl.merchantTerminal')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="app.slug === 'sibs'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="app.mercantil_code"
                :label="`${$t('lbl.merchantTransactionId')}`"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="app.slug === 'paypal'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientsApis[0].client_id"
                label="Client-ID"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="app.slug === 'paypal'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientsApis[0].client_secret"
                label="Client-Secret"
                outlined
                dense
                hide-details="auto"
                :rules="textRequiered"
              ></v-text-field>
            </v-col>

            <fragment v-if="app.slug === 'redsys'">
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="app.mercantil_code"
                  :label="`${$t('lbl.merchantCode')}`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="textRequiered"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="app.terminal"
                  :label="`${$t('lbl.merchantTerminal')}`"
                  outlined
                  dense
                  hide-details="auto"
                  :rules="textRequiered"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="app.currency"
                  :items="currencies"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.currency')"
                  outlined
                  clearable
                  dense
                  hide-details
                ></v-select>
              </v-col>
            </fragment>

            <v-col
              v-if="app.slug !== 'hotetecapi' &&
                app.slug !== 'dingusapi' &&
                app.slug !== 'solwaysapi' &&
                app.slug !== 'paypal'"
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="app.url_prod"
                label="URL"
                outlined
                dense
                hide-details="auto"
                disabled
              ></v-text-field>
            </v-col>

            <v-col
              v-if="app.slug === 'hotetecapi'"
              cols="12"
              md="12"
            >
              <v-text-field
                label="URL"
                outlined
                :value="`${rutaAPI}channel-manager-hotetec`"
                dense
                hide-details="auto"
              >
                <v-tooltip
                  slot="prepend"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="cursor:pointer;"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyToClipboard(`${rutaAPI}channel-manager-hotetec`)"
                    >
                      mdi-link
                    </v-icon>
                  </template>
                  <span>{{ `${rutaAPI}channel-manager-hotetec` }}</span>
                </v-tooltip>
              </v-text-field>
            </v-col>
            <v-col
              v-if="app.slug === 'dingusapi'"
              cols="12"
              md="12"
            >
              <v-text-field
                label="URL"
                outlined
                :value="`${rutaAPI}channel-manager-dingus`"
                dense
                hide-details="auto"
              >
                <v-tooltip
                  slot="prepend"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="cursor:pointer;"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyToClipboard(`${rutaAPI}channel-manager-dingus`)"
                    >
                      mdi-link
                    </v-icon>
                  </template>
                  <span>{{ `${rutaAPI}channel-manager-dingus` }}</span>
                </v-tooltip>
              </v-text-field>
            </v-col>
            <v-col
              v-if="app.slug === 'solwaysapi'"
              cols="12"
              md="12"
            >
              <v-text-field
                label="URL"
                outlined
                :value="`${rutaAPI}channel-manager-solways`"
                dense
                hide-details="auto"
              >
                <v-tooltip
                  slot="prepend"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="cursor:pointer;"
                      v-bind="attrs"
                      v-on="on"
                      @click="copyToClipboard(`${rutaAPI}channel-manager-solways`)"
                    >
                      mdi-link
                    </v-icon>
                  </template>
                  <span>{{ `${rutaAPI}channel-manager-solways` }}</span>
                </v-tooltip>
              </v-text-field>
            </v-col>-->

            <v-col
              cols="12"
            >
              <v-btn
                color="primary"
                outlined
                block
                @click="activeChangeLogo"
              >
                {{ $t('btn.changeLogo') }}
              </v-btn>
              <v-file-input
                id="refInputLogo"
                ref="refInputLogo"
                v-model="logo"
                accept="image/*"
                outlined
                dense
                label="Logo"
                hide-details
                :hide-spin-buttons="true"
                :hide-input="true"
                prepend-icon=""
                @change="setLogo"
              ></v-file-input>
            </v-col>

            <v-col
              cols="12"
            >
              <v-btn
                color="primary"
                outlined
                block
                @click="activeChangeApk"
              >
                {{ $t('btn.changeAPK') }}
              </v-btn>
              <v-file-input
                id="refInputApk"
                ref="refInputApk"
                v-model="apk"
                accept=".apk"
                outlined
                dense
                label="APP"
                hide-details
                :hide-spin-buttons="true"
                :hide-input="true"
                prepend-icon=""
                @change="setAPK"
              ></v-file-input>
            </v-col>

            <!--<v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="logo"
                accept="image/*"
                show-size
                outlined
                dense
                label="Logo"
                hide-details
                @change="setLogo"
              ></v-file-input>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-file-input
                v-model="apk"
                accept=".apk"
                show-size
                outlined
                dense
                label="APK"
                hide-details
                @change="setAPK"
              ></v-file-input>
            </v-col> -->

            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                v-model="app.description"
                :label="$t('lbl.description')"
                outlined
                rows="3"
                row-height="3"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-data-app-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :disabled="camposVerify"
                :loading="loadingBtn"
                @click="$emit('confirmEdit')"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
// eslint-disable-next-line no-unused-vars, import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

export default {
  components: {
    Loading,
  },
  model: {
    prop: 'isSidebarDataAPPActive',
    event: 'update:is-sidebar-data-app-active',
  },
  props: {
    isSidebarDataAPPActive: {
      type: Boolean,
      required: true,
    },
    app: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    type_app: {
      type: String,
    },
  },
  data() {
    return {
      rutaAPI: process.env.VUE_APP_API_URL,
      stepForm: null,
      icons: {
        mdiClose,
      },
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      phoneRules: [v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      loading: false,
      logo: null,
      apk: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      proveedorApis: state => state.app.proveedorApis,
      clientsApis: state => state.app.clientsApis,
    }),
    camposVerify() {
      let rest = false
      if (!this.app.app_name) {
        rest = true
      }

      return rest
    },
  },
  methods: {
    ...mapMutations(['updateProveedorApi', 'addProveedorApi', 'updateClientApi', 'addClientApi']),
    activeChangeLogo() {
      const refInputLogo = document.getElementById('refInputLogo')
      if (refInputLogo != null) {
        refInputLogo.click()
      }
    },
    activeChangeApk() {
      const refInputApk = document.getElementById('refInputApk')
      if (refInputApk != null) {
        refInputApk.click()
      }
    },
    setLogo() {
      if (this.logo) {
        const formData = new FormData()
        formData.append('logo', this.logo)
        formData.append('data', JSON.stringify({ id: this.app.id }))
        this.isLoading = true
        this.axios
          .post('apps/upload-logo', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.app.logo = res.data.data.dir
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    setAPK() {
      if (this.apk) {
        const formData = new FormData()
        formData.append('apk', this.apk)
        formData.append('data', JSON.stringify({ id: this.app.id }))
        this.isLoading = true
        this.axios
          .post('apps/upload-apk', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.app.url_apk = res.data.data.dir
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    copyToClipboard(copyText) {
      // const copyText = document.getElementById(id).textContent
      navigator.clipboard.writeText(copyText)

      this.$toast.info(this.$t('msg.copyText', { n: copyText }))
    },
  },
}
</script>
